import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
      waitingInfo: null
    },
    mutations: {
      setWaitingInfo (waitingInfo) {
        this.state.waitingInfo = waitingInfo
      }
    },
    getters: {
      getWaitingInfo: state => {
			  return state.waitingInfo
		  }
    },
    actions: {},
    modules: {}
})
