<template>
  <div>
    <div class="wapper">
        <div>
            <!-- <searchBtn></searchBtn> -->
            <div class="lineTitle">附近站点<span style="display: inline-block; margin-left: auto; margin-right: 8px; font-size: 14px; color: darkred;">{{ locatingText }}</span></div>
            <div class="line_list">
                <div class="part" v-for="item in lineSiteInfo" :key="item.index">
                    <div class="site">
                        <span>{{ item.name }}</span>
                        <span class="sitedistance">{{ formatDistance(item.distance) }}</span>
                        <span
                            class="near_the_site_next"
                            @click="sameLine(item)">&gt;
                        </span>
                    </div>
                    <div
                        class="site_line_item"
                        v-for="lineItem in item.lines"
                        :key="lineItem.index"
                        @click="lineDetails(item, lineItem)"
                    >
                        <div class="tit">
                            <span class="name">{{ lineItem.name }}</span>
                            <!-- <span class="distance">12分钟/班</span> -->
                        </div>
                        <div class="end_site">
                            <span>开往：{{ lineItem.toStation }}</span>
                            <!-- <span style="float: right">未开通实时信息</span> -->
                        </div>
                    </div>
                </div>
            </div>
            <!--
            <div class="column">
              <router-link to="/">
                    <div class="column_item">
                        <img src="../../assets/line/bus.png" /> <br />乘车
                    </div>
              </router-link>
                -->
                <!-- <router-link to="/LineTransfer">
                 <div class="column_item gray">
                   <img src="../../assets/line/line2.png" /> <br />路线
                 </div>
               </router-link>
            </div>
            -->
            <div class="sm_noneTic" v-bind:class="{ toggle: isShow }">
                <img src="../../assets/line/icon-none.png" alt="无数据" />
                <strong>加载中</strong>
            </div>
            <!--<div>{{ debugText }}</div>-->
        </div>
    </div>
  </div>
</template>


<script>
// eslint-disable-next-line
import gps from '../../store/gps'
import { connectBusHub, getWaitingBusStations } from '../../signalR/busHub'
// import { requestLocation, requestCity } from '../../amap/index.js.bak'
import { wxAutoReady, wxLocate } from '@/utils/api'

export default {
    name: "FjSite",
    data() {
        return {
            isShow: false,
            lineSiteInfo: [],
            city: this.$store.state.currentCity,
            // location: [116.32707, 40.04593],
            location: { lat: null, lon: null },
            locatingText: '定位中...'
            // debugText: ''
        };
    },
    created: function() {
      console.log('index.created')
    },
    mounted: function () {
      this.firstLocating = true
      this.getStations()
      // this.locateCity()
      // this.locate()
    },
    methods: {
      locateWX () {
        const that = this
        that.firstLocating = false

        // that.debugText += 'locateWX ->'
        wxAutoReady(null).then(() => {
          wxLocate().then(res => {
            var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
            var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
            // var speed = res.speed; // 速度，以米/每秒计
            // var accuracy = res.accuracy; // 位置精度
            that.location = { lat: latitude, lon: longitude }
            that.locatingText = '定位成功'
            console.log('成功完成定位')
            console.log(that.location)
            that.getStations()
          }).catch(err => {
            that.locatingText = '定位失败'
            console.log(err)
          })
        }).catch(err => {
          that.locatingText = '定位失败'
          console.log(err)
        })
      },
      formatDistance (distance) {
        if (distance === null) {
          return ''
        }

        if (distance > 1000) {
          return (distance / 1000).toFixed(1) + '公里'
        } else if (distance < 20) {
          return '附近'
        } else if (distance < 100) {
          return '百米内'
        } else {
          return distance.toFixed(1) + '米'
        }
      },
      //获取当前城市
      // locateCity () {
      //   requestCity().then(city => {
      //     console.log(`当前城市为：${city}`)
      //     this.$store.commit('cacheCity', city);
      //   }).catch(err => {
      //     console.log('定位城市失败')
      //     console.log(err)
      //   })
      // },
      // 请求定位
      // locate () {
      //   this.firstLocating = false

      //   requestLocation().then(location => {
      //     this.location = location
      //     this.locatingText = '定位成功'
      //     console.log('成功完成定位')
      //     console.log(this.location)
      //     this.$store.commit('location', { lat: location.lat, lon: location.lon })
      //     this.getStations()
      //   }).catch (err => {
      //     this.locatingText = '定位失败'
      //     console.log(err)
      //     console.log('TODO:定位失败提示')
      //     // this.getStations()
      //   })
      // },
      // 获取等候站点和线路
      getStations () {
        // this.debugText += 'getStations ->'
        console.log('开始获取站点')
        connectBusHub().then((busHub) => {
          console.log(this.location)
          // this.debugText += 'getWaitingBusStations ->'
          // this.debugText += JSON.stringify(this.location)
          // 在微信开发者工具中正常，而在微信APP上经纬度是字符串类型，会导致调用失败
          const lat = Number(this.location.lat) || null
          const lon = Number(this.location.lon) || null
          getWaitingBusStations(busHub, lat, lon).then((stations) => {
            console.log('获取站点完成')
            // this.debugText += 'stations.length = ' + stations.length + ' ->'
            if(stations.length) {
              this.locatingText = '定位成功'
              this.isShow = true
              this.lineSiteInfo = stations
              console.log(stations)
            }
            if (this.firstLocating) {
              // this.locate()
              this.locateWX()
            }
          }).catch(err => {
            // this.debugText += 'getWaitingBusStations-err ->'
            // this.debugText += JSON.stringify(err)
            console.log(err)
          })
        }).catch(err => {
          console.log(err)
        })
      },

      lineDetails: function (item, lineItem) {
          // this.$store.commit('cacheLineInfo', lineItem.lineInfo);
          this.$router.push({
              path: "/txMap",
              query: {
                  busLineId: lineItem.id,
                  direction: lineItem.direction,
                  siteId: item.id
                  // siteName: item.name,
              },
          });
      },

      sameLine: function (item) {
          this.$router.push({
              path: "/sameLine",
              query: {
                  siteId: item.id
                  // siteName: item.name,
              },
          });
      },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
/*新搜索框*/
.toggle {
    display: none;
}
.pop_list_title {
    width: 100%;
    height: 0.88rem;
    padding: 0.14rem 0.2rem;
    background: #36a3f9;
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
}

.pop_list_title .closespan {
    float: right;
    display: block;
    width: 0.9rem;
    height: 0.6rem;
    line-height: 0.6rem;
    font-size: 0.32rem;
    color: #fff;
}

.pop_list_title .input_area {
    float: left;
    width: 70%;
    padding: 0.12rem;
    height: 0.6rem;
    background: #5db4f9;
    margin-left: 0.1rem;
    border-radius: 0.16rem;
}

.pop_list_title .input_area input::-webkit-input-placeholder {
    color: #fff;
}

.pop_list_title .input_area input {
    font-size: 0.28rem;
    color: #fff;
    display: block;
    width: 90%;
    padding-left: 0.52rem;
    height: 0.36rem;
    line-height: 0.36rem;
    background: url(../../assets/line/search2.png) no-repeat left center;
    background-size: 0.36rem 0.36rem;
    border: none;
}
.lineTitle {
    font-size: 0.4rem;
    display: flex;
    align-items: flex-end;
    padding: 0.3rem 0;
}

.line_list .part {
    background: #fff;
    border-radius: 8px;
    color: #292a2c;
    font-size: 0.32rem;
    // height: 2.2rem;
    width: 100%;
    margin-bottom: 0.2rem;
    border-bottom: 1px solid #ebeef5;
    box-shadow: 0px 2px 2px -1px #c0c4cc;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px 0px;
    padding: 0.3rem;
    text-align: left;
}
.line_list .site {
    padding-left: 0.52rem;
    margin-bottom: 0.2rem;
    // background: url(../../assets/line/position.png) left no-repeat;
    background: url(../../assets/line/car2.png) left no-repeat;
    background-size: 0.36rem;
}
.line_list .site_line_item {
    padding: 0.3rem 0;
    border-bottom: 1px solid #e4e7ed;
    &:last-child {
        border-bottom: none;
    }
}
.line_list .tit {
    height: 0.42rem;
    margin-bottom: 0.2rem;
}
.line_list .name {
    float: left;
    border-radius: 4px;
    border: 1px solid #ebeef5;
    border-bottom: 2px solid #409eff;
    border-bottom: 2px solid #3777FF;
    padding: 0 3px;
    // background: url(../../assets/line/car.png) left no-repeat;
    // background-size: 0.36rem 0.36rem;
}
.line_list .distance {
    float: right;
    color: #292a2c;
    // margin-right: 0.35rem;
}
.line_list .sitedistance {
    color: #9a9da0;
    color: darkred;
    font-size: 0.28rem;
    margin-left: 15px;
}
.line_list .near_the_site_next {
    float: right;
    color: #9a9da0;
}
.line_list .distance strong {
    font-size: 0.28rem;
    color: #292a2c;
    margin: 0 0.2rem;
}
.line_list .end_site {
    // padding-left: 0.56rem;
    color: #9a9da0;
    font-size: 0.28rem;
}
</style>
